import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import usStatesObj from 'datasets-us-states-abbr-names';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

const usStatesOptions = Object.keys(usStatesObj).reduce((acc, abbr) => {
    acc.push({label: usStatesObj[abbr], value: abbr}, {label: abbr, value: abbr});
    return acc;
}, []);

class Sanctions extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`sanctions:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'summary', desc: false},
            sanctions: [],
            sanctionsActive: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        fpRest.get('sanctions')
        .then(res => {
            const sanctions = res.data;
            const sanctionsActive = sanctions.filter(({active}) => active);
            console.log(sanctions);
            this.setState({sanctions, sanctionsActive, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadSanctions = sanctions => {
        const getData = this.getData.bind(this);
        const policyPromises = sanctions.map(sanction => fpRest.post('sanctions', {...sanction, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            Alert.success('Sanction Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    Alert.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                Alert.error(`Oops! Something didn't work right. Sanctions weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadSanctions, toggleInactive} = this;
        const {showLoader, initSortOrder, sanctions, sanctionsActive, hideInactive, isImporterOpen, isImportDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Sanctions" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Sanction', link: '/sanctions/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Sanction', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/sanctions-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                ]} />
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={sanctions}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Summary',
                                    accessor: 'summary',
                                },
                                {
                                    Header: 'Date of Violation',
                                    accessor: 'violationDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'
                                },
                                {
                                    Header: 'Sanctioned User',
                                    accessor: 'sactionedUserName',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                }
                            ]}
                            noDataText="No Sanctions Found"
                            onSortedChange={sort => sessionStorage.setItem(`sanctions:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/sanctions/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                <Alert stack={{limit: 3}} effect="slide" html={true} />
                <CsvImporter
                    open={isImporterOpen}
                    onRequestClose={closeImporter}
                    onRequestUpload={res => uploadSanctions(res)}
                    title="Map Sanction Fields"
                    mappingOptions={[
                        {label: 'Company Name', value: 'name', required: true},
                        {label: 'Address 1', value: 'address1'},
                        {label: 'Address 2', value: 'address2'},
                        {label: 'City', value: 'city'},
                        {
                            label: 'State',
                            value: 'state',
                            allowedCellValues: [{value: '', label: ''}, ...usStatesOptions]                          
                        },
                        {label: 'Zip', value: 'zip'},
                        {label: 'Phone 1', value: 'phone1'},
                        {label: 'Phone 2', value: 'phone2'},
                        {label: 'Fax 1', value: 'fax1'},
                        {label: 'Fax 2', value: 'fax2'},
                        {label: 'Website', value: 'website'},
                        {
                            label: 'Type',
                            value: 'typeId',
                            allowedCellValues: [
                                {value: '', label: ''},
                                {value: 1, label: ''},
                                {value: 2, label: ''}
                            ]                       
                        },
                        {
                            label: 'Active',
                            value:'active',
                            required: true,
                            allowedCellValues: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: true, label: 'True'},
                                {value: false, label: 'False'},
                                {value: true, label: '1'},
                                {value: false, label: '0'}                                                          
                            ]
                        }
                    ]}
                />
           </Loader>
        );
    };
}

export default withRouter(Sanctions);