import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import {truncate} from '../helpers/utils';
import EvidenceTable from '../evidence/evidenceTable';

import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';

class BusinessAssociateAgreement extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isSaved: true,
            associateName: '',
            associateId: '',
            summary: '',
            signedDate: '',
            effectiveDate: '',
            endDate: '',
            terminatedDate: '',
            perpetual: false,
            description: '',
            linkedEvidence: []
        };            
    };

    getData = async () => {
        const {id, _new} = this.props;

        if (this.state.showLoader === false) this.setState({showLoader: true});

        try {
            if (!_new) {
                const {data} = await fpRest.get(`business-associates/agreements/${id}`);
                const agreement =  data;

                console.log(agreement);
                this.setState({...agreement, showLoader: false});
            }
            else {
                const {associateId} = this.props;
                const session = sessionStorage.getItem(`associate-${associateId}`);
                const associate = session ? JSON.parse(session) : await fpRest.get(`business-associates/${associateId}`);
                const associateName = associate.data ? associate.data.name : associate.name ? associate.name : 'Not Found';

                this.setState({showLoader: false, active: true, associateName});
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };
    
    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    };
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(linkedEvidence => linkedEvidence.id === id ? false : true);

            this.setState({linkedEvidence});
            Alert.success('Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };    
    
    validate = ({summary}) => {
        let invalidFields = [];
    
        if (!summary) invalidFields.push('"Summary" is required');
    
        return invalidFields;
    };
    
    save = async secondAction => {
        const {id, history} = this.props;
        const {
            summary,
            signedDate,
            effectiveDate,
            endDate,
            terminatedDate,
            perpetual,
            description 
        } = this.state;
        const persistedState = {
            summary,
            signedDate,
            effectiveDate,
            endDate,
            terminatedDate,
            perpetual,
            description          
        };
        const invalidFields = this.validate(persistedState);
        console.log(persistedState);
        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    const {associateId} = this.state;
                    
                    await fpRest.patch(`business-associates/agreements/${id}`, {...persistedState, associateId});
                    if (secondAction === 'close') {
                         history.push(`/business-associates/${associateId}/`);
                    }
                    Alert.success('Associate Agreement Saved');
                }
                else {
                    const {associateId} = this.props;
                    const {data} = await fpRest.post('business-associates/agreements', {...persistedState, associateId});

                    if (secondAction === 'close') {
                        history.push(`/business-associates/${associateId}/`);
                    }
                    else {
                        history.push(`/business-associates/agreements/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                console.log(err);
            }
        }
        else {
            invalidFields.forEach(message => {
                Alert.error(message);
            });
        } 
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new} = this.props;
        const associateId = this.props.associateId ? this.props.associateId : this.state.associateId; 
        const {
            showLoader,
            isSaved,
            associateName,
            summary,
            signedDate,
            effectiveDate,
            endDate,
            terminatedDate,
            perpetual,
            description,
            linkedEvidence         
        } = this.state;
        const {save, onFieldChange, downloadEvidence, onEvidenceUploadComplete, deleteEvidence} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Associate Agreement</span>
                                {summary && <span>: {truncate(summary, 25)}</span>}
                            </span>
                        }
                        actionItems={[
                            {label: <Svg use='close'/>, tip: `${associateName} Contacts`, link: `/business-associates/${associateId}`},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Associate Agreement:</span> {summary}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: `${associateName} Contacts`, link: `/business-associates/${associateId}`},
                            {label: <Svg use='edit'/>,  tip: 'Edit Agreement', link: `/business-associates/agreements/${id}/edit`},
                            {label: <Svg use="add"/>, tip: 'New Agreement', link: `/business-associates/${associateId}/agreements/new`}
                        ]}
                    />
                }
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Business Associate', value: associateName, link: `/business-associates/${associateId}/`}
                        ]}
                    />
                </section>                                    
                <section>
                    <div className="grid-2 card">
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Summary', value: summary, required: true},
                                {label: 'Description', value: description, inputType: 'text'},
                                {label: 'Signed Date', value: signedDate, inputType: 'date'},                              
                                {label: 'Effective Date', value: effectiveDate, inputType: 'date'}
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'End Date', value: endDate, inputType: 'date'},
                                {label: 'Terminated Date', value: terminatedDate, inputType: 'date'},
                                {
                                    label: 'Is this agreement perpetual?',
                                    accessor: 'perpetual',
                                    value: editing ? perpetual : perpetual ? 'yes' : 'No',
                                    inputType: 'toggle',                                 
                                }                        
                            ]}                            
                        />                           
                    </div>    
                </section>
                <section>
                        <div className="cards">
                            {!_new ?
                                <EvidenceTable
                                    data={linkedEvidence}
                                    title="Evidence"
                                    onUploadComplete={onEvidenceUploadComplete}
                                    onRequestDownload={downloadEvidence}
                                    onRequestDelete={deleteEvidence}
                                    isEditing={true}
                                    parentId={id}
                                    typeId={4}
                                />
                            : 
                                <p className="center">Document upload will be available after you save this agreement.</p>
                            }
                        </div>                  
                </section>
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                <Alert stack={{limit: 10}} effect="slide" html={true} />
            </Loader>
        );
    }
}

export default withRouter(BusinessAssociateAgreement);