import React, {Fragment} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Policies from '../policies/policiesRoute';
import Policy from '../policies/policyRoute';
import PolicyStages from '../policies/policyStagesRoute';
import Citations from '../citations/citationsRoute'
import Citation from '../citations/citationRoute'
import BusinessAssociates from '../businessAssociates/businessAssociatesRoute';
import BusinessAssociate from '../businessAssociates/businessAssociateRoute';
//import BusinessAssociateContacts from '../businessAssociates/businessAssociateContactsRoute';
import BusinessAssociateContact from '../businessAssociates/businessAssociateContactRoute';
import BusinessAssociatAgreement from '../businessAssociates/businessAssociatAgreementRoute';
import Assets from '../assets/assetsRoute';
import Asset from '../assets/assetRoute';
import AssetSettings from '../assets/assetSettingsRoute';
import Sanctions from '../sanctions/sanctionsRoute';
import Sanction from '../sanctions/sanctionRoute';
import JobRoles from '../jobRoles/jobRolesRoute';
import MaintenanceLogs from '../maintenanceLogs/maintenanceLogsRoute';
import MaintenanceLog from '../maintenanceLogs/maintenanceLogRoute';
import MaintenanceLogSettings from '../maintenanceLogs/maintenanceLogSettingsRoute';
import SecurityIncidents from '../securityIncidents/securityIncidentsRoute';
import SecurityIncident from '../securityIncidents/securityIncidentRoute';
import SecurityIncidentSettings from '../securityIncidents/securityIncidentSettingsRoute';
import InformationSystemActivityReviews from '../informationSystemActivityReviews/informationSystemActivityReviewsRoute';
import InformationSystemActivityReview from '../informationSystemActivityReviews/InformationSystemActivityReviewRoute';
import Activities from '../activities/activitiesRoute';
import Activity from '../activities/activityRoute';
import SecurityAwareness from '../securityAwareness/securityAwarenessRoute';
import SecurityAwarenessEntry from '../securityAwareness/securityAwarenessEntryRoute';
import Users from '../users/usersRoute';
import User from '../users/userRoute';
//import Company from '../company/companyRoute';
import RiskAssessments from '../riskAssessment/riskAssessmentsRoute';
import RiskAssessment from '../riskAssessment/riskAssessmentRoute';
//import Dashboard from './dashboard/dashboardRoute';

const adminRoutes = [
  {
    path: '/',
    Component: Redirect,
    props: {
      to: '/policies'
    }
  },
  {
    path: '/assets',
    Component: Assets
  },
  {
    Component: Asset,
    switches: [
      {
        path: '/assets/new',
        props: {
          _new: true,
          editing: true,
        }
      },
      {
        path: '/assets/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })
      }
    ]
  },
  {
    path: '/assets/:id/edit',
    Component: Asset,
    props: ({match}) => ({
      id: match.params.id,
      editing: true
    })
  },    
  {
    path: '/business-associates',
    Component: BusinessAssociates
  },
  {
    Component: BusinessAssociate,
    switches: [
      {
        path: '/business-associates/new',
        props: {
          _new: true,
          editing: true,
        }
      },
      {
        path: '/business-associates/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })
      }
    ]
  },
  {
    switches: [
      /*       
      {
        path: '/business-associates/:id/contacts',
        Component: BusinessAssociateContacts,
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })
      },
      */      
      {
        path: '/business-associates/contacts/:id',
        Component: BusinessAssociateContact,
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })
      },      
      {
        path: '/business-associates/agreements/:id',
        Component: BusinessAssociatAgreement,
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })
      },
      {
        path: '/business-associates/:id/edit',
        Component: BusinessAssociate,
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }      
    ]
  },
  {
    switches: [   
      {
        path: '/business-associates/:associateId/contacts/new',
        Component: BusinessAssociateContact,
        props: ({match}) => ({
          editing: true,
          _new: true,
          associateId: match.params.associateId,
        })
      },      
      {
        path: '/business-associates/contacts/:id/edit',
        Component: BusinessAssociateContact,
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      },
      {
        path: '/business-associates/:associateId/agreements/new',
        Component: BusinessAssociatAgreement,
        props: ({match}) => ({
          editing: true,
          _new: true,
          associateId: match.params.associateId,
        })
      },
      {
        path: '/business-associates/agreements/:id/edit',
        Component: BusinessAssociatAgreement,
        props: ({match}) => ({
          id: match.params.id,
          editing: true,
        })
      }           
    ]
  },        
  {
    path: '/citations',
    Component: Citations
  },
  {
    path: '/citations/:id',
    Component: Citation,
    props: ({match}) => ({
      id: match.params.id,
      editing: false
    })    
  },             
  {
    path: '/policies',
    Component: Policies,
  },
  {
    Component: Policy,
    switches: [
      {
        path: '/policies/new',
        props: {
          _new: true,
          editing: true,
        }
      },
      {
        path: '/policies/:id',
        props: locationProps => ({
          id: locationProps.match.params.id,
          editing: false
        })
      },
      {
        path: '/policies/:id/edit',
        props: locationProps => ({
          id: locationProps.match.params.id,
          editing: true
        })
      },
      {
        path: '/policies/:id/new',
        props: locationProps => ({
          id: locationProps.match.params.id,
          newRev: true,
          editing: true
        })
      },
      {
        path: '/policies/:id/:revId',
        props: locationProps => ({
          id: locationProps.match.params.id,
          revId: locationProps.match.params.revId,
          editing: false
        })
      }                          
    ]
  },
  {
    path: '/sanctions',
    Component: Sanctions,
  },
  {
    Component: Sanction,
    switches: [
      {
        path: '/sanctions/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/sanctions/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/sanctions/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/maintenance-logs',
    Component: MaintenanceLogs,
  },
  {
    Component: MaintenanceLog,
    switches: [
      {
        path: '/maintenance-logs/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/maintenance-logs/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/maintenance-logs/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/security-incidents',
    Component: SecurityIncidents,
  },
  {
    Component: SecurityIncident,
    switches: [
      {
        path: '/security-incidents/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/security-incidents/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/security-incidents/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },    
  {
    switches: [
      {
        path: '/settings/users',
        Component: Users,
      },
      /*
      {
        path: '/settings/company',
        Component: Company,
      },
      */             
      {
        path: '/settings/job-roles',
        Component: JobRoles,
      },
      {
        path: '/settings/assets',
        Component: AssetSettings,
      },
      {
        path: '/settings/maintenance-logs',
        Component: MaintenanceLogSettings,
      },
      {
        path: '/settings/security-incidents',
        Component: SecurityIncidentSettings,
      },       
      {
        path: '/settings/policies',
        Component: PolicyStages,
      },       
    ]
  },
  {
    Component: User,
    switches: [
      {
        path: '/settings/users/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/settings/users/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/settings/users/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/information-system-activity-reviews',
    Component: InformationSystemActivityReviews,
  },
  {
    Component: InformationSystemActivityReview,
    switches: [
      {
        path: '/information-system-activity-reviews/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/information-system-activity-reviews/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/information-system-activity-reviews/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/activities',
    Component: Activities,
  },
  {
    Component: Activity,
    switches: [
      {
        path: '/activities/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/activities/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/activities/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/security-awareness',
    Component: SecurityAwareness,
  },
  {
    Component: SecurityAwarenessEntry,
    switches: [
      {
        path: '/security-awareness/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/security-awareness/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/security-awareness/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },
  {
    path: '/risk-assessments',
    Component: RiskAssessments,
  },
  {
    Component: RiskAssessment,
    switches: [
      {
        path: '/risk-assessments/new',
        props: ({match}) => ({
          editing: true,
          _new: true,
        })
      },
      {
        path: '/risk-assessments/:id',
        props: ({match}) => ({
          id: match.params.id,
          editing: false
        })     
      },      
      {
        path: '/risk-assessments/:id/edit',
        props: ({match}) => ({
          id: match.params.id,
          editing: true
        })
      }           
    ]
  },       
];

const baseUserRoutes = [
  {
    path: '/',
    Component: Redirect,
    props: {
      to: '/policies'
    }
  },
  {
    path: '/policies',
    Component: Policies,
  },  
  {
    path: '/policies/:id',
    Component: Policy,
    props: locationProps => ({
      id: locationProps.match.params.id,
      editing: false
    })
  },  
];

const Routes = ({user}) => {
  const buildRoute = (route, _switch) => {
    if (_switch) {
      route = {..._switch, Component: _switch.Component ? _switch.Component : route.Component}
    }
    return <Route exact={true} key={route.path} path={route.path} render={locationProps => <route.Component {...locationProps} {...(typeof route.props === 'function' ? route.props(locationProps) : route.props)}/>} />
  };

  let routes = baseUserRoutes;

  if (user.isAdmin) {
    routes = adminRoutes;
  }
  /*
  const regex = routes.reduce((acc, {switches, path}, i, arr) => {
    const pre = '(?!.*';
    const post = ')';
    
    if (switches) {
      switches.forEach(({path}) => {
        acc = acc + pre + path + post;
      });

    }
    else {
      acc = acc + pre + path + post;
    }

    return i+1 === arr.length ? acc + '.*$' : acc;
    //acc + '(?!.*Dog)(?!.*cat).*$'
  }, '^');
  console.log(regex);
  */
  return (
    <Fragment>
      <Switch>
        {routes.map((route, i) => route.switches ? route.switches.map(_switch => buildRoute(route, _switch)) : buildRoute(route))}
        <Redirect to='/policies'/>
      </Switch>
    </Fragment>    
  );
};

export default Routes;