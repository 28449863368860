import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import EvidenceTable from '../evidence/evidenceTable'
import {truncate} from '../helpers/utils';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import {handleGetError} from '../helpers/errors';
import 'react-s-alert/dist/s-alert-default.css';

class MaintenanceLog extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            hideInactive: false,
            showLoader: true,
            isSaved: true,
            users: [],
            linkedEvidence: [],
            maintenanceTypes: [],
            maintenanceItems: [],           
            itemId: null,
            item: '',
            enteredDate: null,
            maintenanceDate: null,
            typeId: null,
            type: '',
            authorizedById: null,
            authorizedBy: '',
            reason: '',
            performedBy: '',
            description: ''
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            let maintenanceLog = {};
            let users = [];
            let maintenanceTypes = []
            let maintenanceItems = []              

            if (!_new) {
                maintenanceLog = await fpRest.get(`maintenance-logs/${id}`);
                maintenanceLog = maintenanceLog.data;
            }

            if (editing) {
                const res = await Promise.all([fpRest.get(`users`), fpRest.get('maintenance-logs/types'), fpRest.get('maintenance-logs/items')]);
                users = res[0].data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
                maintenanceTypes = res[1].data.map(({id, name}) => ({value: id, label: name}));
                maintenanceItems = res[2].data.map(({id, name}) => ({value: id, label: name}));
            }

            console.log(users);
            this.setState({...maintenanceLog, users, maintenanceTypes, maintenanceItems, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };    

    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    };  
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            Alert.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };      


    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    }; 
    
    validate = ({
        itemId
    }) => {
        let invalidFields = [];

        if (!itemId) invalidFields.push('"Item" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            itemId,
            maintenanceDate,
            typeId,
            authorizedById,
            reason,
            performedBy,
            description
        } = this.state;
        const persistedState = {
            itemId,
            maintenanceDate,
            typeId,
            authorizedById,
            reason,
            performedBy,
            description
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`maintenance-logs/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/maintenance-logs/${id}`);
                    }
                    
                    Alert.success('Log Saved');
                }
                else {
                    const {data} = await fpRest.post('maintenance-logs', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/maintenance-logs/`);
                    }
                    else {
                        history.push(`/maintenance-logs/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                console.log(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                Alert.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/maintenance-logs/${this.props.id}`);
        }
        else {
            this.props.history.push(`/maintenance-logs/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            users,
            linkedEvidence,
            maintenanceTypes,
            maintenanceItems,
            itemId,
            item,
            enteredDate,
            maintenanceDate,
            typeId,
            type,
            authorizedById,
            authorizedBy,
            reason,
            performedBy,
            description
        } = this.state;
        const {downloadEvidence, onEvidenceUploadComplete, deleteEvidence, onFieldChange, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Maintenance Log</span>
                                {item && <span>: {truncate(item, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Maintenance Log:</span> {truncate(item, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Maintenance Logs', link: '/maintenance-logs/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Log', link: `/maintenance-logs/${id}/edit/`},
                            {label: <Svg use="add" />, tip: 'New Log', link: '/maintenance-logs/new/'}
                        ]}
                    />
                }
                <section>
                    {!_new &&
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: enteredDate, inputType: 'date'}
                            ]}
                        />
                    } 
                </section>           
                <section>
                    <div className="grid-3 card">
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Item', value: editing ? itemId : item, accessor:'itemId', inputType: 'select', options: maintenanceItems, required: true},
                                {label: 'Type', value: editing ? typeId : type, accessor:'typeId', inputType: 'select', options: maintenanceTypes},                              
                                {label: 'Maintenance Date', value: maintenanceDate, inputType: 'date', required: true},
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Authorized By', value: editing ? authorizedById : authorizedBy, accessor: 'authorizedById', inputType: 'select', options: users},                       
                                {label: 'Performed By', value: performedBy, accessor:'performedBy'},
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Reason', value: reason, accessor:'reason', inputType: 'textarea'},
                                {label: 'Description', value: description, accessor:'description', inputType: 'textarea'}                       
                            ]}
                        />                        
                    </div>                                              
                </section>
                {!_new &&
                    <section>
                        <EvidenceTable
                            className="card"
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={6}
                        />                    
                    </section>
                }                  
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                <Alert stack={{limit: 10}} effect="slide" html={true} />
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(MaintenanceLog));