import React from 'react';
import {NavLink} from 'react-router-dom'
import Svg from './svg';

const SecondarySideBarNav = ({navItems, closeNav}) => {
    return (
        <nav>
            <ul>
                {navItems.map(item => {
                    const key = item.label.replace(/[^A-Z0-9]+/ig, "_");

                    if (item.icon === '') item.icon = 'dashboard';

                    return(
                        <li onClick={closeNav} key={key}>
                            <NavLink to={item.link} activeClassName="active">
                                <Svg use={item.icon}/>
                                <span className="label">{item.label}</span>
                            </NavLink>                      
                        </li>
                    )}
                )}
            </ul>                    
        </nav>
    );
}

export default SecondarySideBarNav;