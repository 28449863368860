import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Modal from 'react-modal';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import LinkedDataTable from '../helpers/linkedDataTable';
import EvidenceTable from '../evidence/evidenceTable'
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import * as EmailValidator from 'email-validator';
import {handleGetError} from '../helpers/errors';
import 'react-s-alert/dist/s-alert-default.css';

class User extends Component {
    constructor(props) {
        super(props); 

        this.state = {
            showLoader: true,
            isSaved: true,
            modalIsOpen: false,
            modalContent: {},            
            securityRoles: [],
            linkedEvidence: [],
            password: '',
            firstName: '',
            lastName: '',
            email: '',
            createdDate: '',
            role: '',
            roleId: '',
            active: true,
            hiredDate: '',
            terminatedDate: '',
            notes: '',
            linkedJobRoles: []
        };            
    };


    getData = async () => {
        const {id, editing, _new} = this.props;
        let user = [];
        let securityRoles = [];
        let jobRoles = [];

        try {
            if (_new) {
                securityRoles = await fpRest.get(`security-roles`);
            }
            else {
                [user, securityRoles, jobRoles] = await Promise.all([fpRest.get(`users/${id}`), fpRest.get(`security-roles`), fpRest.get(`job-descriptions`)]);
                user = user.data;
                jobRoles = jobRoles.data.map(({name, id}) => ({label: name, value: id}));
            }

            securityRoles = securityRoles.data.map(({id, name}) => ({label: name, value: id}));
            console.log(user, securityRoles);
            this.setState({...user, securityRoles, jobRoles, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };
    
    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };
    
    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            Alert.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };    

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    onJobRolesChange = linkedJobRoles => {
        this.setState({linkedJobRoles, isSaved: false});

        setTimeout(() =>{
            if (!this.state.isSaved) this.save(null, false);
        }, 2000);           
    };

    edit = () => {
        this.props.history.push(`/settings/users/${this.props.id}/edit`)
    };
  
    initResetPasswordEmail= () => {
        this.setState({
            modalIsOpen: true,
            modalContent: {
                header: 'Are you sure you want to send a password reset email to this user?',
                body: <div><button onClick={() => this.sendResetPasswordEmail()} className="button">Yes</button><button onClick={() => this.setState({modalIsOpen: false})} className="button">No</button></div>
            }
        }); 
    };
    
    sendResetPasswordEmail = async () => {
        this.setState({modalIsOpen: false})

        try {
            await fpRest.get(`users/${this.props.id}/send-reset-password-email`);
            Alert.success('The email is on the way!');
        }
        catch (err) {
            Alert.error(`Oops! The password reset email couldn't be sent. Please try again.` );
            console.log(err);           
        }
    };     
    
    validate = ({
        firstName,
        lastName,        
        email,
        roleId,
        active,
        password
    }) => {
        let invalidFields = [];
    
        if (!firstName) invalidFields.push('"First Name" is required');
        if (!lastName) invalidFields.push('"Last Name" is required');
        if (!email) invalidFields.push('"Email" is required');
        if (!EmailValidator.validate(email)) invalidFields.push('"Email" is invalid');
        if (!roleId) invalidFields.push('"Role" is required');        
        //if (!active) invalidFields.push('"Active" is required');
        if (password && password.length < 6) invalidFields.push('"Password" must be at least 6 characters');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            password,
            firstName,
            lastName,
            email,
            roleId,
            active,
            hiredDate,
            terminatedDate,
            notes,
            linkedJobRoles
        } = this.state;
        const persistedState = {
            password,
            firstName,
            lastName,
            email,
            roleId,
            active: active === null ? false : active,
            hiredDate,
            terminatedDate,
            notes,
            linkedJobRoles
        };
        const invalidFields = this.validate(persistedState);
        console.log(persistedState);
        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`users/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/settings/users/${id}`);
                    }
                    
                    this.setState({password: null});
                    Alert.success('User Saved');
                }
                else {
                    const {data} = await fpRest.post('users', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/settings/users/`);
                    }
                    else {
                        history.push(`/settings/users/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                if (err.response && err.response.message === 'Email Taken') {
                    Alert.error('That Email Address Is Already In Use');
                }                
                else if (err.response && err.response.status === 409) {
                    Alert.error('User Already Exists');
                }
                else {
                    Alert.error('Oops! Something went wrong!');
                }
                console.log(err.response);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                Alert.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/settings/users/${this.props.id}`);
        }
        else {
            this.props.history.push(`/settings/users/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            modalIsOpen,
            modalContent,            
            securityRoles,
            jobRoles,
            linkedEvidence,
            password,
            firstName,
            lastName,
            email,
            createdDate,
            role,
            roleId,
            active,
            hiredDate,
            terminatedDate,
            notes,
            linkedJobRoles
        } = this.state;
        const {onEvidenceUploadComplete, downloadEvidence, deleteEvidence, onFieldChange, onJobRolesChange, initResetPasswordEmail, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}User</span>
                                {firstName && <span>: {truncate(firstName, 15)}</span>}
                                {lastName && <span> {truncate(lastName, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Security Awarenesss Entries', link: '/users/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">User:</span> {firstName} {lastName}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Users', link: '/settings/users/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit User', action: edit},
                            {label: <Svg use="add" />, tip: 'New User', link: '/settings/users/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Created Date', value: createdDate, inputType: 'date'}
                            ]}
                        />
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'First Name', value: firstName, accessor:'firstName', required: true},
                            {label: 'Last Name', value: lastName, accessor:'lastName', required: true},
                            {label: 'Email/Username', value: email, accessor:'email', inputType: 'email', required: true},
                            {label: 'Password', value: password, accessor:'password', hide: !editing ? true : false, required: false, placeholder: _new ? 'Leave blank to let the user set their password' : '******', tip: _new ? `If you don't set a password, the user will receive a welcome email with a temporary one. This will allow them to set their own.` : '', inputType: 'password'},
                            {label: 'App Security Level', value: editing ? roleId : role, accessor: 'roleId', inputType: 'select', options: securityRoles, tip: !_new && editing ? `Changes to a user's Securit Role will require them to log out/in, or wait up to 1 hour.` : '', required: true},
                            {label: 'Hired Date', value: hiredDate, accessor:'hiredDate', inputType: 'date'},
                            {label: 'Terminated Date', value: terminatedDate, accessor:'terminatedDate', inputType: 'date'},
                            {label: 'Active', value: editing ? active : active ? 'Yes' : 'No', accessor:'active', inputType: 'toggle', hide: _new ? true : false},
                            {label: 'Notes', value: notes, accessor:'notes', inputType: 'textarea'}
                        ]}
                    />
                    {!_new &&
                        <div className="grid-1"><button className="button" style={{width: '100%'}} onClick={initResetPasswordEmail}>Send Password Reset Email</button></div>
                    }                                              
                </section>
                {_new ?
                    <section><p className="center">Evidence &amp; linked Job Roles can be added after saving.</p></section>
                :
                    <section className="cards grid-2">                          
                        <LinkedDataTable
                            title="Linked Job Roles"
                            dataSet={jobRoles}
                            linkedDataIds={linkedJobRoles}
                            onChange={onJobRolesChange}
                        />
                        <EvidenceTable
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={9}
                        />                 
                    </section>
                }
                <Modal
                    isOpen={modalIsOpen}
                    className="modal-content"
                    closeTimeoutMS={300}
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    {modalContent.header &&                    
                        <h3>{modalContent.header}</h3>
                    }
                    {modalContent.html ? <span dangerouslySetInnerHTML={{__html: modalContent.body}}></span> : <span>{modalContent.body}</span>}
                </Modal>                                                
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                <Alert stack={{limit: 10}} effect="slide" html={true} />
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(User));