import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import Cards from '../helpers/cards';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

class MaintenanceLogSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            maintenanceTypes: [],
            maintenanceItems: [],
            isImporterOpen: false,
            hideInactive: true,
            isNewDropdownOpen: false,
            isImportDropdownOpen: false,
            shouldCreateMaintenanceType: false,
            shouldCreateMaintenanceItem: false      
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const res = await Promise.all([fpRest.get('maintenance-logs/types'), fpRest.get('maintenance-logs/items')]);
            const maintenanceTypes = res[0].data;
            const maintenanceItems = res[1].data;
            console.log(maintenanceTypes, maintenanceItems);
            this.setState({maintenanceTypes, maintenanceItems, showLoader: false});
        }
        catch (err) {
            console.log(err);
        };          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };
    
    toggleNewDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isNewDropdownOpen});
    };

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    createMaintenanceType = () => {
        this.setState({shouldCreateMaintenanceType: true}, () => {
            this.setState({shouldCreateMaintenanceType: false});
        });
    }

    createMaintenanceItem = () => {
        this.setState({shouldCreateMaintenanceItem: true}, () => {
            this.setState({shouldCreateMaintenanceItem: false});
        });
    }    

    saveMaintenanceType = (all, item, type, dest, action) => {
        this.setState({maintenanceTypes: all[0].maintenanceTypes, showLoader: true});
        console.log(type, action);
        if (action === 'create') {
            fpRest.post(`maintenance-logs/types`, type)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
        else if (action === 'update') {
            fpRest.patch(`maintenance-logs/types/${type.id}`, type)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
    };

    saveMaintenanceItem = (all, item, maintenanceItem, dest, action) => {
        this.setState({maintenanceTypes: all[0].maintenanceTypes, showLoader: true});
        console.log(maintenanceItem, action);
        if (action === 'create') {
            fpRest.post(`maintenance-logs/items`, maintenanceItem)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
        else if (action === 'update') {
            fpRest.patch(`maintenance-logs/items/${maintenanceItem.id}`, maintenanceItem)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
    };    

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, toggleNewDropdown, startImport, closeImporter, toggleInactive, saveMaintenanceType, saveMaintenanceItem, createMaintenanceType, createMaintenanceItem} = this;
        const {showLoader, maintenanceTypes, maintenanceItems, hideInactive, isImporterOpen, isImportDropdownOpen, isNewDropdownOpen, shouldCreateMaintenanceType, shouldCreateMaintenanceItem} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                    navItems={settingsHeaderNav('maintenanceTypes')}
                    actionItems={[
                        //{label: <Svg use="add" />, tip: 'New Policy Stage', action: createMaintenanceType},
                        //{label: <Svg use="upload" />, tip: 'Upload Policies', options: [{label: 'Select CSV File', action: startImport}, {label: `${<Svg use="add" />} New Maintenance Type`, action: createMaintenanceType},], isOpen: isNewDropdownOpen, action: toggleNewDropdown},
                    ]} 
                />
                <Cards
                    items={[{name: 'Maintenance Types', maintenanceTypes}]}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag:'h2'
                        },
                        subItems: {
                            accessor: 'maintenanceTypes',
                            newItemText: 'New Maintenance Type',
                            header: {
                                accessor: 'name',
                                tag: 'h3'
                            }
                        }
                    }}
                    areItemsEditable = {false}
                    createNewItem = {shouldCreateMaintenanceType}
                    onSave={saveMaintenanceType}
                />
                <Cards
                    items={[{name: 'Maintenance Items', maintenanceItems}]}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag:'h2'
                        },
                        subItems: {
                            accessor: 'maintenanceItems',
                            newItemText: 'New Maintenance Item',
                            header: {
                                accessor: 'name',
                                tag: 'h3'
                            }
                        }
                    }}
                    areItemsEditable = {false}
                    createNewItem = {shouldCreateMaintenanceItem}
                    onSave={saveMaintenanceItem}
                />                
                <Alert stack={{limit: 3}} effect="slide" html={true} />
            </Loader>
        );
    };
}

export default withRouter(MaintenanceLogSettings);