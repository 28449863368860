import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import Cards from '../helpers/cards';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

class SecurityIncidentSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            incidentCategories: [],
            assetTypes: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            shouldCreateCategory: false      
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const res = await Promise.all([fpRest.get('security-incidents/categories'), fpRest.get('security-incidents/types')])
            const incidentTypes = res[1].data;
            const incidentCategories = res[0].data.map(category => ({                         
                ...category,
                incidentTypes: incidentTypes.filter(({securityIncidentCategoryId}) => securityIncidentCategoryId === category.id)
            }));
            console.log(incidentTypes);
            this.setState({incidentCategories, showLoader: false});
        }
        catch (err) {
            console.log(err)
        };          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen: !this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    createCategory = () => {
        this.setState({shouldCreateCategory: true}, () => {
            this.setState({shouldCreateCategory: false});
        });
    };

    saveCard = (incidentCategories, category, type, dest, action) => {
        this.setState({incidentCategories, showLoader: true});

        if (type) {
            if (action === 'update') {
                fpRest.patch(`security-incidents/types/${type.id}`, type)
                .then(res => {
                    this.setState({showLoader: false});
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });
            }
            else if (action === 'create') {
                type.securityIncidentCategoryId = category.id;
                fpRest.post(`security-incidents/types`, type)
                .then(res => {
                    this.getData();
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });            
            }
            else if(action === 'move') {
                    fpRest.patch(`security-incidents/types/${type.id}`, {...type, securityIncidentCategoryId: dest.id})
                    .then(res => {
                        this.setState({showLoader: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.getData();
                    });
            }            
        }
        else {
            if (action === 'update') {
                fpRest.patch(`security-incidents/categories/${category.id}`, category)
                .then(res => {
                    this.setState({showLoader: false});
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });
            }
            else if ('create') {
                fpRest.post(`security-incidents/categories`, category)
                .then(res => {
                    this.getData();
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });          
            }                        
        }
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, toggleInactive, saveCard, createCategory} = this;
        const {showLoader, incidentCategories, users, hideInactive, isImporterOpen, isImportDropdownOpen, shouldCreateCategory} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                    navItems={settingsHeaderNav('incidents')}
                    actionItems={[
                        {label: <Svg use="add" />, tip: 'New Category', action: createCategory},
                    ]} 
                />
                <Cards
                    items={incidentCategories}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag: 'h2'
                        },
                        newItemText: 'New Security Incident Catagory',
                        subItems: {
                            accessor: 'incidentTypes',
                            newItemText: 'New Security Incident Type',
                            header: {
                                accessor: 'name',
                                tag: 'h3'
                            },
                            isAddingNewItem: ''
                        }
                    }}
                    createNewItem = {shouldCreateCategory}
                    onSave={saveCard}
                />
                <Alert stack={{limit: 3}} effect="slide" html={true} />
            </Loader>
        );
    };
}

export default withRouter(SecurityIncidentSettings);