const getConfig = stage => {
    const dev = {
        cognito: {
            Auth: {
                identityPoolId: 'us-east-1:0da6f00a-6cee-4f4c-bfdb-18110c7b34f9',
                region: 'us-east-1',
                userPoolId: 'us-east-1_5xHuViqaM',
                userPoolWebClientId: 'a90kqgci203hti351lsnl6k8'
            }
        },
        apiUrl: 'https://s0fogmuqoh.execute-api.us-east-1.amazonaws.com/dev/'
    };

    const staging = {
        cognito: {
            Auth: {
                identityPoolId: 'us-east-1:0da6f00a-6cee-4f4c-bfdb-18110c7b34f9',
                region: 'us-east-1',
                userPoolId: 'us-east-1_5xHuViqaM',
                userPoolWebClientId: 'a90kqgci203hti351lsnl6k8'
            }
        },
        apiUrl: 'https://mg8pyycq6i.execute-api.us-east-1.amazonaws.com/staging/'
    };

    const prod = {
        cognito: {
            Auth: {
                identityPoolId: 'us-east-1:afd9449c-fffb-4c1a-81a6-c210960c976b',
                region: 'us-east-1',
                userPoolId: 'us-east-1_sYZgvFw5M',
                userPoolWebClientId: '4deejagegeja1qoa8qh1r7v3uc'
            }
        },
        apiUrl: 'https://9uu27uuib0.execute-api.us-east-1.amazonaws.com/prod/'
    };

    if (stage === 'staging') {
        return staging;
    }
    else if (stage === 'prod') {
        return prod;
    }
    else {
        return dev;
    }
};

const config = getConfig(process.env.REACT_APP_STAGE);

export default config;