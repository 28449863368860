import 'core-js';
import registerServiceWorker from './registerServiceWorker';
import fpRest from './helpers/fpRest';
import ReactDOM from 'react-dom';
import React, { Component, Fragment } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import withAuth from './auth/withAuth';
import IdleTimer from 'react-idle-timer'
import SecondarySideBarNav from './helpers/secondarySideBarNav';
import MainNavButton from './helpers/mainNavButton';
import Svg from './helpers/svg';
import CurrentUserMenu from './helpers/currentUserMenu'
import './index.css';
import KassoufLogo from './kassouf-logo.png';
import KassoufLogoMark from './kassouf-logo-mark.png';
import Routes from './helpers/routes';
import bustCache from './helpers/bustCache';

export const CurrentUserContext = React.createContext({});
export const UiContext = React.createContext({});

const scrollMainNavUp = () => {
	const nav = document.getElementById('main-nav');
	const sideBar = document.getElementById('main-sidebar');

	nav.scrollTop = nav.scrollTop - (sideBar.offsetHeight + 300);
};

const scrollMainNavDown = () => {
	const nav = document.getElementById('main-nav');
	const sideBar = document.getElementById('main-sidebar');

	nav.scrollTop = nav.scrollTop + (sideBar.offsetHeight - 300);
};

class Flightplan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idle: false,
			appVersionOnServer: null,
			mainBarSize: 'wide',
			pageLoaded: true,
			secondarySideBarStatus: 'inactive',
			secondarySideBarNav: [],
			currentUserMenuIsOpen: false,
			showNavScrollUp: false,
			showNavScrollDown: false
		};
	};

	setAppInfo = async () => {
		const {data} = await fpRest.get('app-info');
		bustCache(data.webAppVersion);
		//this.setState({appVersionOnServer: data.webAppVersion});
	};

	setCurrentUser = async (payload, shouldRefresh) => {
		const setCurrentUser = this.setCurrentUser;
		const signOut = this.signOut;

		if (payload) {
			payload.jobRoleIds = JSON.parse(payload.jobRoleIds);
			payload.id = parseInt(payload.id, 10);
			payload.roleId = parseInt(payload.roleId, 10);
			payload.authorizedEndpoints = JSON.parse(payload.authorizedEndpoints);
			payload.isAdmin = payload.authorizedEndpoints.filter(({ func }) => func === '*').length > 0 ? true : false;
			this.setState({ currentUser: payload });
			console.log(payload);
		}

		if (shouldRefresh !== false) {
			setTimeout(async () => {
				try {
					const cognitoUser = await Auth.currentAuthenticatedUser();
					const currentSession = await Auth.currentSession();
					cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
						if (session) {
							setCurrentUser(session.idToken.payload, this.state.idle ? false: true);
						}
						else {
							signOut();	
						}
					});
				}
				catch (err) {
					console.log('Unable to refresh Token', err);
				}
			}, 1000 * 60 * 5);
		}
	};

	signOut = async () => {
		try {
			await Auth.signOut();
			window.location.assign(window.location.origin);
		}
		catch (err) {
			console.log(err);
		}
	};

	toggleViewSize = () => {
		if (this.state.mainBarSize === `slim`) {
			this.setState({ mainBarSize: `wide` });
		}
		else {
			this.setState({ mainBarSize: `slim` });
		}
	};

	onActive = () => {
		console.log('a');
		this.setState({idle: false});
		this.setCurrentUser();
	};

	onIdle = () => {
		console.log('i');
		this.setState({idle: true});
	};
	
	onMainNavScroll = e => {
		const nav = e.target;

		if (nav.scrollTop === 0) {
			console.log('foo');
			this.setState({showNavScrollUp: false});
		}
		else {
			this.setState({showNavScrollUp: true});	
		}		

		if (nav.scrollHeight - nav.scrollTop === nav.clientHeight) {
			this.setState({showNavScrollDown: false});
		}
		else {
			this.setState({showNavScrollDown: true});
		}
	};
		

	secondarySideBarOpen = (label, nav) => {
		this.setState({ secondarySideBarStatus: 'active', mainSideBarNavActiveItem: label, secondarySideBarNav: nav() });
	};

	secondarySideBarClose = () => {
		this.setState({ secondarySideBarStatus: 'inactive', mainSideBarNavActiveItem: '' });
	};

	toggleUserMenu = () => {
		this.setState({ currentUserMenuIsOpen: !this.state.currentUserMenuIsOpen });
	};

	handleMenuState = () => {
		if (window.innerWidth <= 1200) {
			this.setState({ mainBarSize: `slim` });
		}
	};

	handleMainNavScrollState = () => {
		this.setState({showNavScrollDown: window.innerHeight > 940 ? false : true});
	};

	onWindowResize = () => {
		this.handleMenuState();
		this.handleMainNavScrollState();
	};

	homeNav = () => [
		{ label: 'Dashboard', icon: 'dashboard', link: '/dashboard' },
		{ label: 'Reports', icon: 'chart', link: '/reports' },
		{ label: 'Risk Analysis', icon: 'warning', link: '/risk-analysis' },
		{ label: 'Activities', icon: 'touch', link: '/activities' },
		{ label: 'All Attached Evidence', icon: 'attach', link: '/evidence' },
		{ label: 'Logging & tracking', icon: 'list-add', link: '/logging' }
	];

	policiesNav = () => [
		{ label: 'HIPAA Reference & Policy Gaps', icon: 'explore', link: '/citations' },
		{ label: 'Policies & Procedures', icon: 'group-folder', link: '/policies' },
		//{label: 'My Policies', icon: 'user-folder', link: '/my-policies'},   
		//{label: 'Assignments', icon: 'assignment-complete', link: '/assignments'},
		//{label: 'Policy Gaps', icon: 'gap', link: '/policy-gaps'},
	];

	safeguardsNav = () => [
		{ label: 'Risk Analysis', icon: 'warning', link: '/risk-analysis' },
		{ label: 'Security Awareness & Trainting', icon: 'lightbulb', link: '/security-awareness' },
		{ label: 'Business Associates', icon: 'briefcase', link: '/business-associates' },
		{ label: 'Security Incidents', icon: 'flag', link: '/security-incidents' },
		{ label: 'Sanctions', icon: 'gavel', link: '/sanctions' }
	];

	configurationNav = () => [
		{ label: 'Users', icon: 'users', link: '/users' },
		//{label: 'Job Descriptions', icon: 'jobs', link: 'job-descriptions'},
		{ label: 'Company', icon: 'business', link: '/company' },
		{ label: 'Setup Tables', icon: 'grid', link: '/setup' },
	];

	componentDidMount() {
		console.log(Auth);
		Auth.currentSession()
		.then(session => {
			console.log(session);
			this.setCurrentUser(session.idToken.payload);
		});

		this.onWindowResize();
		window.addEventListener('resize', this.onWindowResize);
		this.setAppInfo();
	};

	render() {
		const {appVersionOnServer, secondarySideBarStatus, mainSideBarNavActiveItem, secondarySideBarNav, mainBarSize, pageLoaded, currentUserMenuIsOpen, currentUser,showNavScrollUp, showNavScrollDown} = this.state;
		const {handleMenuState, signOut, toggleViewSize, toggleUserMenu, onMainNavScroll, onIdle, onActive} = this;
		//const {authState} = this.props;
		//const secondarySideBarHome = this.secondarySideBarOpen.bind(this, 'home', this.homeNav);
		//const secondarySideBarPolicies = this.secondarySideBarOpen.bind(this, 'policies_procedures', this.policiesNav);
		//const secondarySideBarSafeguards = this.secondarySideBarOpen.bind(this, 'administrative_safeguards', this.safeguardsNav);
		//const secondarySideBarConfiguration = this.secondarySideBarOpen.bind(this, 'configuration', this.configurationNav);
		const secondarySideBarClose = this.secondarySideBarClose;
		const pageSize = mainBarSize === `slim` ? 'wide' : '';
		const pageActive = pageLoaded ? 'active' : '';

		return (
			<div>	
				{currentUser &&
					<UiContext.Provider value={{ pageSize }}>
						<CurrentUserContext.Provider value={currentUser}>
							<Router>
								<Fragment>
									<IdleTimer
										onActive={onActive}
										onIdle={onIdle}
										debounce={250}
										timeout={1000 * 60 * 10}
									/>

									{currentUser.isAdmin ?
										<div>
											<div id="main-sidebar" className={`main-sidebar ${mainBarSize} ${pageActive}`}>
												<section className="logo-wrap">
													<img className="logo" src={KassoufLogo} alt="Logo" />
													<img className="logo-mark" src={KassoufLogoMark} alt="Logo Mark" />
												</section>
												<section className="current-user">
													<button className="no-style" onClick={toggleUserMenu}><Svg use="user" /><span className="user-name">{currentUser && currentUser.firstName}</span></button>
												</section>
												{showNavScrollUp &&
													<div className="scroll-controls up" onClick={scrollMainNavUp}>
														<button className="no-style">
															<Svg use="arrow-up"/>
														</button>
													</div>				
												}								
												<nav id="main-nav" onScroll={onMainNavScroll}>
													<ul>
							{/*
							<li>
							<MainNavButton action={secondarySideBarHome} label={'Home'} icon={'home'} mainActiveItem={mainSideBarNavActiveItem} /> 
							</li>
							*/}
														<li>
															<MainNavButton link="/policies" action={handleMenuState} label='Policies &amp; Procedures' icon='assignment' mainActiveItem={mainSideBarNavActiveItem} />
														</li>
														<li>
															<MainNavButton link="/risk-assessments" action={handleMenuState} label='Risk Assessments' icon='assessment-warn' mainActiveItem={mainSideBarNavActiveItem} />
														</li>														
														<li>
															<MainNavButton link="/business-associates" action={handleMenuState} label='Business Associates' icon='briefcase' mainActiveItem={mainSideBarNavActiveItem} />
															{/*<MainNavButton action={secondarySideBarSafeguards} label={'Administrative Safeguards'} icon={'shield'} mainActiveItem={mainSideBarNavActiveItem} />*/}
														</li>
														<li>
															<MainNavButton link="/citations" action={handleMenuState} label='Rulesets & Gaps' icon='explore' mainActiveItem={mainSideBarNavActiveItem} />
														</li>														
														<li>
															<MainNavButton link="/sanctions" action={handleMenuState} icon='gavel' label='Sanctions' />
														</li>
														<li>
															<MainNavButton link="/assets" action={handleMenuState} icon='devices' label='Assets' />
														</li>
														<li>
															<MainNavButton link="/maintenance-logs" action={handleMenuState} icon='check-board' label='Maintenance Logs' />
														</li>
														<li>
															<MainNavButton link="/security-incidents" action={handleMenuState} icon='warning' label='Security Incidents' />
														</li>
														<li>
															<MainNavButton link="/information-system-activity-reviews" action={handleMenuState} icon='assessment1' label='Information System Activity Reviews' />
														</li>
														<li>
															<MainNavButton link="/activities" action={handleMenuState} icon='check-mark' label='Activities' />
														</li>
														<li>
															<MainNavButton link="/security-awareness" action={handleMenuState} icon='grad-cap' label='Security Awareness' />
														</li>																																																																															
														<li>
															<MainNavButton link="/settings/users" action={handleMenuState} icon='gear' label='Settings' />
														</li>	
														{/*
							<li>
							<MainNavButton action={secondarySideBarConfiguration} label={'Configuration'} icon={'gear'} mainActiveItem={mainSideBarNavActiveItem} />
							</li>
							*/}
													</ul>
												</nav>
												{showNavScrollDown &&
													<div className="scroll-controls">
														<button className="no-style" onClick={scrollMainNavDown}>
															<Svg use="arrow-down"/>
														</button>
													</div>
												}
											</div>
											<div className={`secondary-sidebar ${secondarySideBarStatus} ${mainBarSize}`}>
												<button className="button close" onClick={secondarySideBarClose}>
													<Svg use="close" />
												</button>
												<SecondarySideBarNav navItems={secondarySideBarNav} closeNav={secondarySideBarClose} />
											</div>
											<button onClick={toggleViewSize} className={`main-sidebar-controls ${mainBarSize} ${pageActive}`}>
												<Svg use="backburger" />
											</button>
											<div onClick={toggleViewSize} className={`sidebar-shade ${mainBarSize}`}></div>
											<main className={`view ${pageSize}`}>
					<Routes user={currentUser} />
											</main>
										</div>
										:
										<div>
											<div id="main-sidebar" className={`main-sidebar ${mainBarSize} ${pageActive}`}>
												<section className="logo-wrap">
													<img className="logo" src={KassoufLogo} alt="Logo" />
													<img className="logo-mark" src={KassoufLogoMark} alt="Logo Mark" />
												</section>
												<section className="current-user">
													<button className="no-style" onClick={toggleUserMenu}><Svg use="user" /><span className="user-name">{currentUser && currentUser.firstName}</span></button>
												</section>
												<nav>
													<ul>
														<li>
															<MainNavButton link="/policies" label='My Policies' icon='assignment' mainActiveItem={mainSideBarNavActiveItem} />
														</li>
													</ul>
												</nav>
											</div>
											<button onClick={toggleViewSize} className={`main-sidebar-controls ${mainBarSize} ${pageActive}`}>
												<Svg use="backburger" />
											</button>
											<main className={`view ${pageSize}`}>
						<Routes user={currentUser} />
					</main>
										</div>
									}
									<footer className="main-footer">
										<span>FlightPlan v2.0 - &copy; 20202<a target="_blank" rel="noopener noreferrer" href="http://www.skysailsoft.com">SkySail Software</a></span>
									</footer>
									<CurrentUserMenu user={currentUser} isOpen={currentUserMenuIsOpen} onRequestClose={toggleUserMenu} onRequestSignOut={signOut} />
								</Fragment>
							</Router>
						</CurrentUserContext.Provider>
					</UiContext.Provider>
				}
			</div>	
		);
	}
}

const App = withAuth(Flightplan);

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();