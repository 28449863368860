import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import Cards from '../helpers/cards';
import List from '../helpers/list';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

class JobRoles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            departments: [],
            departmentsActive: [],
            assetTypes: [],
            users: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            shouldCreateDepartment: false      
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const res = await Promise.all([fpRest.get('job-descriptions?activeOnly=false'), fpRest.get('departments?activeOnly=false'), fpRest.get(`users`)])
            const jobRoles = res[0].data;
            const departments = res[1].data.map(department => ({                         
                ...department,
                jobRoles: jobRoles.filter(({departmentId}) => departmentId === department.id)
            }));
            const departmentsActive = departments.filter(({active}) => active);
            const users = res[2].data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
         
            this.setState({departments, departmentsActive, users, showLoader: false});
        }
        catch (err) {
            console.log(err)
        };          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    createDepartment = () => {
        this.setState({shouldCreateDepartment: true}, () => {
            this.setState({shouldCreateDepartment: false});
        });
    }

    saveCard = (departments, department, jobRole, dest, action) => {
        this.setState({departments, showLoader: true});
        if (jobRole) {
            if (action === 'update') {
                fpRest.patch(`job-descriptions/${jobRole.id}`, jobRole)
                .then(res => {
                    this.setState({showLoader: false});
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });
            }
            else if (action === 'create') {
                jobRole.departmentId = department.id;
                fpRest.post(`job-descriptions`, jobRole)
                .then(res => {
                    this.getData();
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });            
            }
            else if(action === 'move') {
                    fpRest.patch(`job-descriptions/${jobRole.id}`, {...jobRole, departmentId: dest.id})
                    .then(res => {
                        this.setState({showLoader: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.getData();
                    });
            }            
        }
        else {
            if (action === 'update') {
                fpRest.patch(`departments/${department.id}`, department)
                .then(res => {
                    this.setState({showLoader: false});
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });
            }
            else if ('create') {
                fpRest.post(`departments`, department)
                .then(res => {
                    this.getData();
                })
                .catch(err => {
                    console.log(err);
                    this.getData();
                });          
            }                        
        }
    };

    uploadJobRoles = jobRoles => {
        const getData = this.getData.bind(this);
        const policyPromises = jobRoles.map(jobRole => fpRest.post('jobRoles', {...jobRole, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            Alert.success('Asset Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    Alert.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                Alert.error(`Oops! Something didn't work right. JobRoles weren't imported`);
            }
        });
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadJobRoles, toggleInactive, saveCard, createDepartment} = this;
        const {showLoader, departments, departmentsActive, users, hideInactive, isImporterOpen, isImportDropdownOpen, shouldCreateDepartment} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                    navItems={settingsHeaderNav('jobRoles')}
                    actionItems={[
                        {label: <Svg use="add" />, tip: 'New Department', action: createDepartment},
                        //{label: <Svg use="upload" />, tip: 'Upload Job Role', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/jobRoles-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                    ]} 
                />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Active Departments', value: departmentsActive.length},
                            {label: 'Hide Inactive', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive}
                        ]}
                    />
                </section>     
                <Cards
                    items={hideInactive ? departmentsActive : departments}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag: 'h2'
                        },
                        metaData: [
                            {label: 'Department Supervisor', accessor: 'supervisorName', accWhenEditing: 'supervisorId', inputType: 'select', options: users},
                            {label: 'Department Active', accessor: 'active', defaultValue: true, inputType: 'toggle'}
                        ],
                        newItemText: 'New Department',
                        subItems: {
                            accessor: 'jobRoles',
                            newItemText: 'New Job Role',
                            header: {
                                accessor: 'name',
                                tag: 'h3'
                            },
                            body: {
                                accessor: 'description'
                            },
                            isAddingNewItem: ''
                        }
                    }}
                    createNewItem = {shouldCreateDepartment}
                    onSave={saveCard}
                />
                <Alert stack={{limit: 3}} effect="slide" html={true} />
                <CsvImporter
                    open={isImporterOpen}
                    onRequestClose={closeImporter}
                    onRequestUpload={res => uploadJobRoles(res)}
                    title="Map Asset Fields"
                    mappingOptions={[
                        {label: 'Company Name', value: 'name', required: true},
                        {label: 'Address 1', value: 'address1'},
                        {label: 'Address 2', value: 'address2'},
                        {label: 'City', value: 'city'},
                        {label: 'Zip', value: 'zip'},
                        {label: 'Phone 1', value: 'phone1'},
                        {label: 'Phone 2', value: 'phone2'},
                        {label: 'Fax 1', value: 'fax1'},
                        {label: 'Fax 2', value: 'fax2'},
                        {label: 'Website', value: 'website'},
                        {
                            label: 'Type',
                            value: 'typeId',
                            allowedCellValues: [
                                {value: '', label: ''},
                                {value: 1, label: ''},
                                {value: 2, label: ''}
                            ]                       
                        },
                        {
                            label: 'Active',
                            value:'active',
                            required: true,
                            allowedCellValues: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: true, label: 'True'},
                                {value: false, label: 'False'},
                                {value: true, label: '1'},
                                {value: false, label: '0'}                                                          
                            ]
                        }
                    ]}
                />
           </Loader>
        );
    };
}

export default withRouter(JobRoles);