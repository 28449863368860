import React, {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Toggle from '../helpers/toggle';
import 'react-datepicker/dist/react-datepicker.css';

const ListItem = ({index, label, rightLabel, value, editing, inlineLabel, inputType, accessor, options, onChange, required, link, newWindow, hide, placeholder, tip}) => {
    const camelCase = (str) => {
        const noSpaces = str.replace(/ /g, '');

        return noSpaces.charAt(0).toLowerCase() + noSpaces.slice(1);
    }
    
    const onFieldChange = e => {
        const value = e && e.target ? e.target.value : e.value;
           
        onChange({
            index,
            accessor, 
            value
        });
    };

    const toggle = () => {
        onChange({
            index,
            accessor, 
            value: !value
        });       
    };

    const onDateChange = date => {
        onChange({
            index,
            accessor, 
            value: date ? moment(date).format() : null
        });        
    };

    const onDateTimeChange = date => {
        onChange({
            index,
            accessor, 
            value: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null
        });        
    };    

    const input = () => {
        if (inputType === 'custom') {
            return <div>{value !== null ? value : 'Not Set'}</div>
        }
        else if (inputType === 'textarea') {
            return <textarea value={value !== null ? value : ''} onChange={onFieldChange}/>
        }
        else if (inputType === 'select') {
            value= value !== null ? value : '';

            return (
                <Select
                    name={label}
                    clearable={false}
                    className="select"
                    classNamePrefix="select"                                        
                    value={options.filter(option => value === option.value)[0]}
                    onChange={onFieldChange}
                    options={options.map(({label, value}) =>
                        ({label, value})
                    )}
                />                  
            );
        }
        else if (inputType === 'toggle') {
            return <Toggle on={value} onClick={toggle} noOff={rightLabel ? true : false}/>
        }     
        else if (inputType === 'date') {
            const date = value ? new Date(value) : null;

            return <DatePicker placeholderText="Select Date" isClearable={true} strictParsing dateFormat="MM/dd/yyyy" selected={date} onChange={onDateChange}/>
        }
        else if (inputType === 'dateTime') {
            const date = value ? new Date(value) : null;

            return <DatePicker placeholderText="Select Date" isClearable={true} showTimeInput={true} timeInputLabel="Time:" timeFormat="HH:mm" dateFormat="MM/dd/yyyy h:mm aa" selected={date} onChange={onDateTimeChange}/>
        }                  
        else {
            return <input type={inputType ? inputType : 'text'} placeholder={placeholder ? placeholder : ''} value={value !== null ? value : ''} onChange={onFieldChange}/>
        }        
    };

    const viewValue = () => {
        if (value === null || value === '') value = 'Not Set';
        if (value !== 'Not Set' && inputType === 'toggle') value = value || value == 'Yes' ? 'Yes' : 'No';
        if (value !== 'Not Set' && inputType === 'date') value = moment(value).format('MM/DD/Y');
        if (value !== 'Not Set' && inputType === 'dateTime') value = moment(value).format('MM/DD/Y h:mm A');
        if (value !== 'Not Set' && inputType === 'textarea') value = <span className="textarea-view" dangerouslySetInnerHTML={{ __html: value.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>;
        if (value !== 'Not Set' && link) value = <a href={link} target={newWindow ? '_blank' : '_self'}>{value}</a>;

        return <span><span className="label-separator">: </span>{value}</span>;
    };

    const inlineLabelStyle = {
        display: 'inline',
        marginRight: '8px'
    }

    //accessor = accessor ? accessor : camelCase(label);
    accessor = accessor || typeof label !== 'string' ? accessor : camelCase(label);
    
    return (
        <Fragment>
            {!hide &&
                <li className="list-item">
                    {editing ?
                        <div data-tip={tip ? tip : ''}>
                            <label className="label" style={inlineLabel ? inlineLabelStyle : {display: 'block'}}>{label}{required && <span className="required"> *</span>}</label>
                            
                            {inlineLabel ?
                                <div style={{display: 'inline-block', minWidth: inputType === 'toggle' ? '32px' : '320px'}}>{input()}</div>
                            :
                                input()
                            }
                            {rightLabel &&
                                <label className="label" style={{display: 'inline', marginLeft: '8px'}}>{rightLabel}</label>
                            }
                        </div> 
                    :
                        <div>
                            <span className="label">{label}</span>
                            {typeof value !== 'undefined' && viewValue()}
                        </div>
                    }
                    <ReactTooltip/>
                </li>
            }   
        </Fragment>
    );
};

const List = ({className, items, editing, inlineLabels, onChange}) => {
    return (
        <ul className={className}>
            {items.map((item, i) => {
                const {label, rightLabel, value, inputType, accessor, options, required, link, newWindow, hide, placeholder, tip} = item;
                
                return <ListItem key={i} index={i} label={label} rightLabel={rightLabel} value={value} editing={item.editing || item.editing === false ? item.editing : editing} inlineLabel={inlineLabels} inputType={inputType} accessor={accessor} options={options} required={required} onChange={item.onChange ? item.onChange : onChange} link={link} newWindow={newWindow} hide={hide} placeholder={placeholder} tip={tip}/>
            })}
        </ul>
    );
};

export {ListItem};
export default List;