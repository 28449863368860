import React, { Component } from 'react';
import Modal from 'react-modal';
import {withRouter} from 'react-router-dom';
import withCurrentUser from '../helpers/withCurrentUser';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import List from '../helpers/list';
import {defaultTableFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import ReactTooltip from 'react-tooltip';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

const short = (string, num) => {
    return string.length > num ? string.replace(/(<([^>]+)>)/ig,"").slice(0, num).trim() + '...' : string.replace(/(<([^>]+)>)/ig,"");
};

class Citation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            rulesOptions: [],
            sectionOptions: [],
            numOfCitationsAddressed: 0,
            modalIsOpen: false,
            modalContent: {} 
        };
    };

    getData = () => {
        const {id} = this.props;
        const apiCalls = [fpRest.get(`citations/${id}`), fpRest.get(`policies`)];

        if (this.state.showLoader === false) this.setState({showLoader: true});

        Promise.all(apiCalls)
        .then(res => {
            console.log(res);
            const policies = res[1].data;
            const citation = res[0].data;

            this.setState({...citation, showLoader: false});
        })
        .catch(err => console.log(err));           
    };

    openModal = (type, id) => {
        let modalContent = null;

        if (type === 'guidance') {
            modalContent = this.state.linkedGuidance.filter(item => item.id === id)
            .map(item => {
                return {
                    header: <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Date Published', value: item.datePublished},
                            {label: 'Source', value: 'View Document', link: item.sourceLink, newWindow: true}
                        ]}
                    />,
                    closeButton: true,
                    body: <div>                      
                        <div className="text-view" dangerouslySetInnerHTML={{__html: item.text}}></div>
                    </div>
                }            
            })[0];
        }       

        this.setState({modalIsOpen: true, modalContent});        
    };

    closeModal = () => {
        this.setState({modalIsOpen: false});
    };    

    componentDidMount() {
        this.getData();      
    };

    render() {
        //const {history, currentUser} = this.props;
        const {showLoader, name, ruleName, sectionName, desc, text, linkedGuidance, modalIsOpen, modalContent} = this.state;
        const {openModal, closeModal} = this;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title={
                        <span>
                            <span className="brand-red">Citation:</span> {name}
                        </span>
                    }
                    actionItems={[
                        {label: <Svg use='explore'/>, tip: 'All Citations', link: '/citations'},
                    ]}
                />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Rule', value: ruleName},
                            {label: 'Section', value: sectionName},
                            {label: 'Description', value: desc}
                        ]}
                    />
                </section>               
                <section>
                    <div className="card">
                        <div className="text-view" dangerouslySetInnerHTML={{__html:text}}></div>
                    </div>
                </section>
                <section className="grid-2 cards">
                    <div>
                        <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Guidance from HHS</span></h2>
                        <ReactTable
                            data={linkedGuidance}
                            columns={[
                                {Header: 'id', accessor:'id', show: false},
                                {
                                    Header: 'Published',
                                    accessor:'datePublished',
                                    width: 240,
                                    Cell: ({value}) => moment(value).utcOffset('-05:00').format('MM/DD/Y')
                                },
                                {
                                    Header: 'Guidance',
                                    accessor:'text',
                                    Cell: ({value}) => short(value, 40)
                                }
                            ]}
                            className="small-table"
                            noDataText="No Guidance Available"
                            defaultPageSize={200}
                            minRows={20}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            showPaginationBottom={false}
                            defaultFilterMethod={defaultTableFilter}                          
                            getTdProps={(state, rowInfo, column, instance) => ({
                                style: {cursor: 'pointer'},
                                onClick: e => rowInfo && openModal('guidance', rowInfo.row.id)
                            })}                            
                        />                         
                    </div> 
                </section>
                <Modal
                    isOpen={modalIsOpen}
                    //onAfterOpen={this.afterOpenModal}
                    onRequestClose={closeModal}
                    closeTimeoutMS={300}
                    className="modal-content header-footer"
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    <header>
                        <button className="button close" onClick={closeModal}>
                            <Svg use="close"/>
                        </button>
                        <div>{modalContent.header}</div>
                    </header>
                    <div className="inner">{modalContent.body}</div>
                </Modal>                                 
                <ReactTooltip/>
                <Alert stack={{limit: 3}} effect="slide" html={true} />
           </Loader>
        );
    };
}

export default withCurrentUser(withRouter(Citation));