import React, {Fragment, Component} from 'react';
import Svg from './svg';
import List from './list';
import 'react-datepicker/dist/react-datepicker.css';
import {goToAnchor} from 'react-scrollable-anchor'



const scrollWindow = e => {
    const y = e.clientY; //This collects details on where your mouse is located vertically
    const container = window; //Replace this with the element class(.) or id(#) that needs to scroll
    const buffer = 300; //You can set this directly to a number or dynamically find some type of buffer for the top and bottom of your page
    const containerBottom = container.innerHeight; //Find the bottom of the container
    const containerTop = 0; //Find the top position of the container
    const scrollPosition = container.scrollY; //Find the current scroll position
    const scrollRate = 25; //increase or decrease this to speed up or slow down scrolling

    if (containerBottom - y < buffer) { //If the bottom of the container's position minus y is less than the buffer, scroll down!
        container.scroll(0, scrollPosition + scrollRate);
    } else if (containerTop + y < buffer) { //If the top of the container's position plus y is less than the buffer, scroll up!
        container.scroll(0, scrollPosition - scrollRate);
    }
}

const Item = ({className, onChange, isEditing, onSave, onEdit, onCancelEdit, showDragHandle, isDraggable, onHandleMouseDown, onDragStart, onDragEnd, onDrop, header, body, id}) => {
    return (
        <Fragment>
            {isEditing ?
                <li className={`card editing ${className}`}>
                    <h3 className="header-alt-1 clear-fix">
                        <input autoFocus type="text" value={header} placeholder="Name" className="no-style" onChange={e => onChange({header: e.target.value, body, id})}/>
                    </h3>
                    {body !== null &&
                        <div className="body"><textarea value={body} placeholder="Description" onChange={e => onChange({header, body: e.target.value, id})}></textarea></div>
                    }
                    <div className='grid-2'>
                        <button className="button" onClick={onSave}>Save</button><button className="button" onClick={onCancelEdit}>Cancel</button>
                    </div>
                </li>
            :
                <li draggable={isDraggable} onDragStart={onDragStart} onDragEnd={onDragEnd} onDrop={e => {onDrop(); e.preventDefault()}} onDragOver={e => e.preventDefault()} key={id} className={`card ${className}`}>
                    <h3 className={`${body !== null ? 'header-alt-1' : 'h1'} clear-fix`}>
                        {header}
                        <div className="corner">
                            {showDragHandle &&
                                <span onMouseDown={onHandleMouseDown} className="handle"><Svg use="reorder"/></span>
                            }
                            <button onClick={onEdit} className="no-style edit-button"><Svg use="edit"/></button>
                        </div>
                    </h3>
                    {body !== null &&
                        <div className="body textarea-view" dangerouslySetInnerHTML={{ __html: body ? body.replace(/(?:\r\n|\r|\n)/g, '<br>') : ''}}></div>
                    }
                </li>
            }
        </Fragment>
    );
}

class Cards extends Component {
    constructor(props) {
        super(props)
        //items, mappings, onChange, createNewItem

        this.state = {
            isCreatingNew: false,
            isShowingDropZones: false,
            isClickingEditButton: false,
            dropTargetLocation: null,
            itemToEditLocation: null,
            itemToMoveLocation: null,
            mappedItems: []    
        };
    };

    setDropTarget = (e, itemIndex) => {
        this.setState({dropTargetLocation: itemIndex});
    };

    changeDragState = (e, itemIndex, subItemIndex, isDragable) => {
        const {mappedItems} = this.state;

        mappedItems[itemIndex].subItems[subItemIndex].isDraggable = isDragable;
        this.setState({mappedItems, itemToMoveLocation: isDragable ? {itemIndex, subItemIndex} : null});
        
        return isDragable ? document.addEventListener('mousemove', scrollWindow) : document.removeEventListener('mousemove', scrollWindow);
    };
    
    showHideDropZones = (state) => {
        setTimeout(() => this.setState({isShowingDropZones: state}), 50);
    };

    newItem = () => {
        const {mappings} = this.props;
        const {mappedItems, isCreatingNew} = this.state;

        if (!isCreatingNew) {
            mappedItems.push({header: '', metaData: mappings.metaData ? mappings.metaData.map(item => ({...item, value: item.defaultValue ? item.defaultValue : ''})) : [], subItems: []});
            this.setState({
                itemToEditLocation: {itemIndex: mappedItems.length - 1, subItemIndex: null},
                isCreatingNew: true,
                mappedItems
            }, () => {
                goToAnchor(`card-item-${mappedItems.length - 1}`, false);
            });            
        }
    };

    editItem = (itemIndex, subItemIndex) => {
        this.setState({itemToEditLocation: {itemIndex, subItemIndex}, isClickingEditButton: true});
    };
    
    changeItemHeader = (e, itemIndex) => {
        const {mappedItems} = this.state;

        mappedItems[itemIndex].header = e.target.value;
        this.setState({mappedItems});
    }; 
    
    changeMeta = (itemIndex, {index, accessor, value}) => {
        const {mappedItems} = this.state;

        mappedItems[itemIndex].metaData[index].value = value;
        this.setState({mappedItems});
    };
    
    changeSubItem = (fields, itemIndex, subItemIndex) => {
        const {mappedItems} = this.state;

        mappedItems[itemIndex].subItems[subItemIndex].id = fields.id;
        mappedItems[itemIndex].subItems[subItemIndex].header = fields.header;
        mappedItems[itemIndex].subItems[subItemIndex].body = fields.body;
        this.setState({mappedItems});
    };

    newSubItem = (itemIndex) => {
        const {mappedItems} = this.state;
        const {mappings} = this.props;
        console.log(mappings.subItems.body);

        mappedItems[itemIndex].subItems.push({header: '', body: mappings.subItems.body ? '' : null});
        console.log(mappedItems[itemIndex].subItems);
        this.setState({
            itemToEditLocation: {itemIndex, subItemIndex: mappedItems[itemIndex].subItems.length - 1},
            isCreatingNew: true,
            mappedItems
        });
    };

    save = () => {
        const {onSave, items, mappings} = this.props;
        const {mappedItems, itemToEditLocation, itemToMoveLocation, dropTargetLocation, isCreatingNew} = this.state;
        const action = isCreatingNew ? 'create' : itemToMoveLocation ? 'move' : 'update';

        let item = null
        let subItem = null;
        let dest = null;

        if (action === 'move') {
            const {itemIndex, subItemIndex} = itemToMoveLocation;

            item = items[itemIndex];
            subItem = {...items[itemIndex][mappings.subItems.accessor][subItemIndex]};
            dest = items[dropTargetLocation];
            console.log('dropTargetLocation', dropTargetLocation);
            items[dropTargetLocation][mappings.subItems.accessor].push(subItem);
            items[itemIndex][mappings.subItems.accessor] = items[itemIndex][mappings.subItems.accessor].filter((subItem, i) => i !== subItemIndex);

            if (dropTargetLocation === itemIndex) return null;
        }
        else {
            const {itemIndex, subItemIndex} = itemToEditLocation;

            if (subItemIndex || subItemIndex === 0) {
                const mappedSubItem = mappedItems[itemIndex].subItems[subItemIndex];

                item = items[itemIndex];
                if (action === 'create') items[itemIndex][mappings.subItems.accessor].push({});
                items[itemIndex][mappings.subItems.accessor][subItemIndex][mappings.subItems.header.accessor] = mappedSubItem.header;
                if (mappedSubItem.body !== null) items[itemIndex][mappings.subItems.accessor][subItemIndex][mappings.subItems.body.accessor] = mappedSubItem.body;

                subItem = items[itemIndex][mappings.subItems.accessor][subItemIndex]
            }
            else {
                const mappedItem = mappedItems[itemIndex];

                if (action === 'create') items.push({});
                items[itemIndex][mappings.header.accessor] = mappedItem.header;
                mappedItem.metaData.forEach((metaDataItem, metaDataIndex) => {
                    if (mappings.metaData[metaDataIndex].accWhenEditing) {
                        const option = metaDataItem.options.filter(option => metaDataItem.value === option.value);

                        items[itemIndex][mappings.metaData[metaDataIndex].accWhenEditing] = metaDataItem.value;
                        items[itemIndex][mappings.metaData[metaDataIndex].accessor] = option.length ? option[0].label : null;
                    }
                    else {
                        items[itemIndex][mappings.metaData[metaDataIndex].accessor] = metaDataItem.value;
                    }
                });

                item = items[itemIndex];
                console.log(item);
            }
        }

        this.setState({itemToEditLocation: null, itemToMoveLocation: null, dropTargetLocation: null, isShowingDropZones: false, isCreatingNew: false}, () => {
            onSave(items, item, subItem, dest, action);
        });
    };   

    cancelEdit = () => {
        this.setState({itemToEditLocation: null, isCreatingNew: false, items: this.state.items});
    };

    isEditing = (location, index1, index2) => {
        if (location) {
            if (index1 === location.itemIndex && index2 === location.subItemIndex) {
                return true;
            }
            else if (index1 === location.itemIndex && index2 === null && location.subItemIndex === null) {
                return true;
            }
            else {
                return false
            }                
        }
        else {
            return false;
        }
    };    
    
    componentDidUpdate(prevProps) {
        if (this.props.createNewItem) {
            this.newItem();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {items, mappings} = props;
        const {itemToEditLocation, isClickingEditButton, itemToMoveLocation} = state;
        const mapItems = (items, itemMap, itemToEditLocation) => items ? items.map((item, i) => ({
            id: itemMap.id ? item[itemMap.id.accessor] : '',
            header: itemMap.header ? item[itemMap.header.accessor] : '',
            body: itemMap.body ? item[itemMap.body.accessor] === null ? '' : item[itemMap.body.accessor] : null,
            metaData: itemMap.metaData ? itemMap.metaData.map(({label, accessor, accWhenEditing, inputType, options, requird}) => ({label, value: itemToEditLocation && itemToEditLocation.itemIndex === i ? item[accWhenEditing ? accWhenEditing : accessor] : item[accessor], inputType: inputType, options: options, requird})) : [],
            subItems: itemMap.subItems ? mapItems(item[itemMap.subItems.accessor], itemMap.subItems, i) : [],
        })) : []; 

        if ((itemToEditLocation && isClickingEditButton === false) || itemToMoveLocation) {
            return null;
        }
        else return ({
            mappedItems: mapItems(items, mappings, itemToEditLocation),
            isClickingEditButton: false
        })
    };

    render() {
        const {changeDragState, showHideDropZones, setDropTarget, newItem, changeItemHeader, changeMeta, changeSubItem, newSubItem, save, cancelEdit, editItem, isEditing} = this;
        const {mappings, areItemsEditable} = this.props;
        const {itemToEditLocation, mappedItems, isShowingDropZones, dropTargetLocation} = this.state;

        return (
            <section className={`grid-1 cards-section${itemToEditLocation ? ' editing' : ''}`}>
                {mappedItems.map(({id, header, metaData, subItems}, itemIndex) =>
                    <div key={itemIndex} id={`card-item-${itemIndex}`} className={`card${isEditing(itemToEditLocation, itemIndex, null) ? ' editing': ''}`}>
                        {header !== null && typeof header !== 'undefined'  &&
                            <h2>
                                {isEditing(itemToEditLocation, itemIndex, null) ? <input className="center" type="text" placeholder="Name" value={header} onChange={e => changeItemHeader(e, itemIndex)} /> : header}
                                {areItemsEditable !== false &&
                                    <button onClick={() => editItem(itemIndex, null)} className="no-style edit-button corner"><Svg use="edit"/></button>
                                }
                           </h2>
                        }
                        {metaData &&
                            <List
                                className="meta"
                                inlineLabels={true}
                                onChange={field => changeMeta(itemIndex, field)}
                                editing={isEditing(itemToEditLocation, itemIndex, null)} 
                                items={metaData}
                            />
                        } 
                        <ul className="grid-2 sub-items">
                            {subItems.map(({id, header, body, isDraggable}, i) =>
                                <Item key={i} showDragHandle={mappedItems.length > 1} isDraggable={isDraggable} isEditing={isEditing(itemToEditLocation, itemIndex, i)} header={header} body={body} id={id} onChange={fields => changeSubItem(fields, itemIndex, i)} onEdit={() => editItem(itemIndex, i)} onHandleMouseDown={e => changeDragState(e, itemIndex, i, true)} onDragStart={e => showHideDropZones(true)} onDragEnd={e => {changeDragState(e, itemIndex, i, false); showHideDropZones(false);}} onDrop={save} onChange={fields => changeSubItem(fields, itemIndex, i)} onEdit={() => editItem(itemIndex, i)} onCancelEdit={cancelEdit} onSave={save}/> 
                            )}
                            {!itemToEditLocation &&
                                <li onClick={() => newSubItem(itemIndex)} className="card button foreshadow"><button className="button foreshadow center" style={{display: 'block'}}><Svg use='add'/> {mappings.subItems.newItemText}</button></li>
                            }
                        </ul>
                        {isEditing(itemToEditLocation, itemIndex, null) &&
                            <div className='grid-2'>
                                <button className="button" onClick={save}>Save</button><button onClick={cancelEdit} className="button">Cancel</button>
                            </div>
                        }                        
                        {isShowingDropZones &&
                            <div className={`drop-zone${dropTargetLocation === itemIndex ? ' receive-drop' : ''}`} onDragEnter={e => setDropTarget(e, itemIndex)} onDragLeave={e => setDropTarget(e, null)} onDrop={save} onDragOver={e => e.preventDefault()}>
                                Drop ↓
                            </div>
                        }
                    </div>
                )}
                {!itemToEditLocation && mappings.header && areItemsEditable !== false &&            
                    <button className="button foreshadow" onClick={newItem}><Svg use='add'/> {mappings.newItemText}</button>
                }
            </section>        
        );
    };
};

//export {Card};
export default Cards;