import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-table/react-table.css';

class Activities extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`activities:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'enteredDate', desc: true},
            users: [],
            activities: [],
            activitiesOpen: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false      
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        Promise.all([fpRest.get('activities'), fpRest.get('users')])
        .then(res => {
            const activities = res[0].data.map(activity => ({...activity, completed: activity.completedDate ? true : false}));
            const activitiesOpen = activities.filter(({completed}) => !completed);
            const users = res[1].data.map(({firstName, lastName}) => ({value: `${firstName} ${lastName}`, label: `${firstName} ${lastName}`}));
            console.log(activities);
            this.setState({users, activities, activitiesOpen, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadActivities = activities => {
        const getData = this.getData.bind(this);
        const policyPromises = activities.map(log => fpRest.post('activities', {...log, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            Alert.success('Activities Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    Alert.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                Alert.error(`Oops! Something didn't work right. Activities weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadActivities, toggleInactive} = this;
        const {showLoader, initSortOrder, users, activities, activitiesOpen, hideInactive, isImporterOpen, isImportDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Activities" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Activities', link: '/activities/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Activities', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/activities-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Open Activities', value: activitiesOpen.length},
                            {label: 'Hide Closed', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive}
                        ]}
                    />
                </section>                
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={hideInactive ? activitiesOpen : activities}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Entered Date',
                                    accessor: 'enteredDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'description',
                                },
                                {
                                    Header: 'Due Date',
                                    accessor: 'dueDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },                               
                                {
                                    Header: 'Assigned To',
                                    accessor: 'assignedUser',
                                    Filter: ({onChange}) => <DorpDownFilter options={users} action={onChange.bind(this)}/>            
                                },
                                {
                                    show: hideInactive ? false : true,
                                    Header: 'Status',
                                    accessor: 'completed',
                                    Cell: row => row.value ? 'Completed' : 'Incomplete',
                                    Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Incomplete', value: false}, {label: 'Completed', value: true}]} action={onChange.bind(this)}/>            
                                }                                                                                                                                                                      
                            ]}
                            noDataText="No Logs Found"
                            onSortedChange={sort => sessionStorage.setItem(`activities:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/activities/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                <Alert stack={{limit: 3}} effect="slide" html={true} />
           </Loader>
        );
    };
}

export default withRouter(Activities);